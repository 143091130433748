import { MapPin } from 'lucide-react'
import { useLocation } from 'react-router-dom'
import { useAreaData, useMapPois } from './helper/queries'
import { useEffect, useRef, useState } from 'react'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { MapContainer, Marker, Polygon, Popup, TileLayer } from 'react-leaflet'
import { isMobile } from 'react-device-detect'

import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import './overrides.css'

import {
  getPaddedBounds,
  mappingMarker,
  PoiData,
  swapCoords,
} from './helper/client'

export default function Map() {
  const mapRef = useRef<L.Map>(null)
  const location = useLocation()
  const [places, setPlaces] = useState<PoiData[]>([])
  const [contentLoading, setContentLoading] = useState(true)

  const { data: aData, error: aError, isLoading: aLoading } = useAreaData()

  const { data, status, error, isLoading } = useMapPois({
    provinceSlug: 'chaiyaphum',
    coordinates: aData?.coordinates,
  })

  useEffect(() => {
    if (data && data.payload) {
      setPlaces(data.payload.places)
      setContentLoading(false)
    }
  }, [data])

  useEffect(() => {
    return () => {
      setPlaces([])
    }
  }, [location])

  const openNavigation = (
    e: React.MouseEvent,
    location: PoiData['location']
  ) => {
    e?.preventDefault()
    if (location && location.lat && location.lng) {
      // open google map navigation to lat/lng
      const { lat, lng } = location
      window
        .open(
          `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`,
          '_blank'
        )
        ?.focus()
    }
  }

  const openPoiDetail = (e: React.MouseEvent, slug: string) => {
    e?.preventDefault()
    window.open(`https://www.tripniceday.com/place/${slug}`, '_blank')?.focus()
  }

  if (aLoading) return <div>Loading...</div>
  if (aError) return <div>{aError.message}</div>

  const mapCenter: L.LatLngExpression = aData.center
  const coords: L.LatLngExpression[][] = swapCoords(aData.coordinates)
  const paddedBounds = getPaddedBounds(coords)

  if (isLoading) return <div>Loading...</div>
  if (status !== 'pending' && error) {
    alert('ข้อมูลที่ส่งเข้ามาไม่ถูกต้อง')
    window.location.href = '/'
    return null
  }

  return (
    <div className='map-wrapper'>
      {/* topbar */}
      <div className='topbar'>
        <div className='left flex flex-row items-center gap-x-1'>
          <div className='flex h-10 w-10 items-center justify-center'>
            <MapPin className='block' />
          </div>
          <h1 className='text-lg leading-none'>จังหวัดชัยภูมิ</h1>
        </div>
        <div className='logo'>
          <img
            src='/logo/tat-and-tnd.png'
            alt='TAT & TripNiceDay'
            className='block h-14 max-w-full'
          />
        </div>
      </div>
      {/* map-block */}
      <div className='map-container relative'>
        {contentLoading ? <LoadingScreen /> : null}
        <MapContainer
          center={mapCenter}
          zoom={isMobile ? 8 : 9}
          scrollWheelZoom={true}
          className='map-block'
          maxBounds={paddedBounds}
          bounds={paddedBounds}
          maxBoundsViscosity={1.0}
          ref={mapRef}
          attributionControl={false}>
          <TileLayer
            minZoom={8}
            maxZoom={16}
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
          />
          <Polygon
            positions={coords}
            fillOpacity={0.1}
            fillColor='#38849F'
            weight={3}
            color='#38849F'
          />
          {places && places.length > 0 ? (
            <MarkerClusterGroup
              chunkedLoading
              showCoverageOnHover={false}
              disableClusteringAtZoom={14}
              maxClusterRadius={40}
              spiderfyOnMaxZoom={false}>
              {places && places.length > 0
                ? places.map((place) => {
                    const category = place.categories[0]
                    return (
                      <Marker
                        key={place.id}
                        position={[place.location.lat, place.location.lng]}
                        title={place.name}
                        draggable={false}
                        icon={mappingMarker(category.slug)}
                        alt={place.name}
                        zIndexOffset={1}
                        autoPan={true}>
                        <Popup
                          closeButton={false}
                          closeOnEscapeKey={true}
                          maxWidth={280}
                          minWidth={280}
                          className='map-infowindow'>
                          <div className='infowindow-wrapper'>
                            <div
                              className='place-cover'
                              style={{
                                background: `black url(${place.image.thumbnail}) no-repeat center`,
                                backgroundSize: 'cover',
                              }}
                            />
                            <div className='place-info'>
                              <div className='upper w-full'>
                                <h3>
                                  <button
                                    title={place.name}
                                    onClick={(e) =>
                                      openPoiDetail(e, place.slug)
                                    }>
                                    {place.name}
                                  </button>
                                </h3>
                                <h4>{category.name}</h4>
                              </div>
                              <div className='lower w-full'>
                                <p>{place.description}</p>
                                <div className='flex w-full min-w-0 flex-row items-start justify-between gap-x-1'>
                                  <button
                                    onClick={(e) =>
                                      openNavigation(e, place.location)
                                    }
                                    type='button'
                                    className='button sm primary w-full'>
                                    นำทาง
                                  </button>
                                  <button
                                    onClick={(e) =>
                                      openPoiDetail(e, place.slug)
                                    }
                                    type='button'
                                    className='button sm secondary w-full'>
                                    ดูข้อมูล
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popup>
                      </Marker>
                    )
                  })
                : null}
            </MarkerClusterGroup>
          ) : null}
        </MapContainer>
      </div>
    </div>
  )
}

const LoadingScreen = () => {
  return (
    <div className='absolute inset-0 z-50 flex min-h-screen w-screen flex-col items-center justify-center'>
      <div className='absolute z-10 h-full w-full animate-pulse bg-white/70'></div>
      <h3 className='relative z-20 text-center text-2xl'>Loading</h3>
    </div>
  )
}
