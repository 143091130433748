import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { apiURL } from './client'

interface FetchDataModel {
  provinceSlug: string
  coordinates?: L.LatLngExpression[][][]
}

export const useAreaData = () => {
  return useQuery({
    queryKey: ['area-data'],
    queryFn: async () => {
      const response = await axios.get(`../dataset/area.json`)
      // find area that match object slug
      if (response.status !== 200) {
        throw new Error('ไม่พบข้อมูลที่ต้องการเรียกดู')
      }
      return response.data
    },
    placeholderData: (prev) => prev,
  })
}

export const useMapPois = ({ provinceSlug, coordinates }: FetchDataModel) => {
  // remove the outer ring before sending to the API
  return useQuery({
    queryKey: ['map-data'],
    queryFn: async () => {
      const response = await axios.post(
        `${apiURL}/v4/map/pois`,
        {
          province: provinceSlug,
          coordinates: coordinates ? JSON.stringify(coordinates[0]) : null,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'client-name': 'tnd-client',
          },
        }
      )
      if (response.status !== 200) {
        throw new Error('Failed to fetch map data')
      }
      return response.data
    },
    placeholderData: (prev) => null,
    enabled: !!coordinates,
  })
}
