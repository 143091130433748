import L from 'leaflet'

export interface PoiData {
  id: string
  name: string
  slug: string
  description: string
  location: {
    lat: number
    lng: number
  }
  image: {
    cover: string
    thumbnail: string
  }
  categories: [
    {
      id: string
      name: string
      slug: string
    },
  ]
}

export const apiURL = `${process.env.REACT_APP_API_ENDPOINT}`

export const defaultMarker = L.icon({
  iconRetinaUrl: '/markers/marker-icon@2x.png',
  iconUrl: '/markers/marker-icon.png',
  iconSize: [24, 37],
  iconAnchor: [12, 37],
  popupAnchor: [0, -28],
})

export const foodieMarker = L.icon({
  iconRetinaUrl: '/markers/normal/food@2x.png',
  iconUrl: '/markers/normal/food.png',
  iconSize: [28, 40],
  iconAnchor: [14, 40],
  popupAnchor: [0, -28],
})

export const hotelMarker = L.icon({
  iconRetinaUrl: '/markers/normal/hotel@2x.png',
  iconUrl: '/markers/normal/hotel.png',
  iconSize: [28, 40],
  iconAnchor: [14, 40],
  popupAnchor: [0, -28],
})

export const sacredMarker = L.icon({
  iconRetinaUrl: '/markers/normal/sacred@2x.png',
  iconUrl: '/markers/normal/sacred.png',
  iconSize: [28, 40],
  iconAnchor: [14, 40],
  popupAnchor: [0, -28],
})

export const attractionMarker = L.icon({
  iconRetinaUrl: '/markers/normal/attraction@2x.png',
  iconUrl: '/markers/normal/attraction.png',
  iconSize: [28, 40],
  iconAnchor: [14, 40],
  popupAnchor: [0, -28],
})

export const mappingMarker = (categorySlug: string) => {
  if (!categorySlug) return defaultMarker
  switch (categorySlug) {
    case 'restaurant-and-cafe':
      return foodieMarker
    case 'accommodation':
      return hotelMarker
    case 'spiritual':
      return sacredMarker
    case 'tourist-attraction':
      return attractionMarker
    default:
      return defaultMarker
  }
}

export const swapCoords = (coordinates: L.LatLngExpression[][]) => {
  return coordinates.map((cord) =>
    cord.map(([lat, lng]: any) => [lng, lat] as L.LatLngExpression)
  )
}

// Function to calculate the bounds with padding (in degrees)
export const getPaddedBounds = (
  coordinates: L.LatLngExpression[][],
  latPadding = 0.5, // Adjust padding (latitude)
  lngPadding = 0.5 // Adjust padding (longitude)
) => {
  const latLngArray = coordinates.flat()
  const latitudes = latLngArray.map(([lat]: any) => lat)
  const longitudes = latLngArray.map(([, lng]: any) => lng)

  const southWest: [number, number] = [
    Math.min(...latitudes) - latPadding,
    Math.min(...longitudes) - lngPadding,
  ]
  const northEast: [number, number] = [
    Math.max(...latitudes) + latPadding,
    Math.max(...longitudes) + lngPadding,
  ]

  return [southWest, northEast] as [[number, number], [number, number]]
}
